// src/pages/CancelPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

function CancelPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Inform the user that consent was canceled
    alert('Consent was canceled. No transactions will be fetched.');
    
    // Optionally, redirect to the Settings or Dashboard page after a delay
    const timer = setTimeout(() => {
      navigate('/settings');
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container text-center mt-5">
      <h2>Consent Canceled</h2>
      <p>You will be redirected shortly...</p> 
    </div>
  );
}

export default CancelPage;

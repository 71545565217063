// src/pages/Pricing.js

import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import './Pricing.css'; // Import the custom CSS for Pricing
import { Link } from 'react-router-dom'; // Import Link for navigation

function Pricing() {
  return (
    <div>
      {/* Navigation Bar */}
      <Navbar bg="light" expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand as={Link} to="/">TransferTracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/pricing" active>Pricing</Nav.Link>
              <Nav.Link as={Link} to="/loginPage">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Pricing Section */}
      <Container className="section" style={{height: "100vh"}}>
        <h2>How much will it cost?</h2>
        <div className="pricing-container">
          {/* Standard Package */}
          <div className="package">
            <h3>Standard Package</h3>
            <p>
              Our Standard Package is perfect for businesses who need access to our software's powerful look-up features. You can refresh statements as many times as you want, allowing you to gather the essential information you need. It's an excellent option for businesses of all sizes. All for £32.00 per month, paid by direct debit.
            </p>
          </div>

          {/* Vertical HR between packages */}
          <div className="vertical-hr"></div>

          {/* Premium Package */}
          <div className="package">
            <h3>Premium Package</h3>
            <p>
              Upgrade to the Premium Package for unlimited lookups and push notifications of arrival of filtered transfers to selected staff members by SMS or email. Refreshes every day. This package is charged at £47.50 per month, paid by direct debit.
            </p>
          </div>
        </div>
      </Container>

      {/* Footer */}
      <div className="footer">
        Powered by GlobalCharge
      </div>
    </div>
  );
}

export default Pricing;

// src/components/Header.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

function Header() {
  const { auth } = useContext(AuthContext); // Get user data from Auth context
  const [logoUrl, setLogoUrl] = useState('./pngtree-gray-network-placeholder-png-image_3416659.jpg'); // Default logo

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        // Check if auth object, user, and company are defined before proceeding
        if (auth && auth.user && auth.user.company) {
          const response = await axios.get(`/api/settings/getLogo`, {
            headers: { Authorization: `Bearer ${auth.token}` },
            params: { company: auth.user.company },
          });

          if (response.data && response.data.logoUrl) {
            setLogoUrl(response.data.logoUrl); // Set the logo URL from response
          } else {
            setLogoUrl('./pngtree-gray-network-placeholder-png-image_3416659.jpg'); // Default logo if none is available
          }
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
        setLogoUrl('./pngtree-gray-network-placeholder-png-image_3416659.jpg'); // Set default logo if there's an error
      }
    };

    // If the logo is stored in auth context, use it; otherwise, fetch from API
    if (auth && auth.user && auth.companyLogo) {
      setLogoUrl(auth.companyLogo);
    } else {
      fetchLogo();
    }
  }, [auth]);

  return (
    <div className="header-logo">
      <img src={logoUrl} alt={(auth && auth.user && auth.company) || 'Company Logo'} />
    </div>
  );
}

export default Header;

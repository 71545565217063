// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    role: localStorage.getItem('role'),
    company: localStorage.getItem('company'),
    companyLogo: localStorage.getItem('companyLogo'),
  });

  useEffect(() => {
    if (auth.token) {
      localStorage.setItem('token', auth.token);
      localStorage.setItem('role', auth.role);
      localStorage.setItem('company', auth.company); 
      localStorage.setItem('companyLogo', auth.companyLogo);
      localStorage.setItem('user', JSON.stringify(auth.user));
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('company');
      localStorage.removeItem('companyLogo');
      localStorage.removeItem('role');
    }
  }, [auth]);

  const isAuthenticated = !!auth.token;

  return (
    <AuthContext.Provider value={{ auth, setAuth, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

import React, { useState, useEffect } from 'react';
import './AddToHomeScreenPrompt.css';

const isIosDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const AddToHomeScreenPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptFallback, setPromptFallback] = useState(false);

  useEffect(() => {
    if (!isIosDevice()) {
      const handleBeforeInstallPrompt = (event) => {
        // Prevent automatic prompt and save event
        event.preventDefault();
        setDeferredPrompt(event);
        setShowPrompt(true);
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      // Fallback to show the prompt manually if not automatically triggered within 30 seconds
      const fallbackTimeout = setTimeout(() => {
        if (!deferredPrompt) {
          setPromptFallback(true);
        }
      }, 30000); // 30 seconds

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        clearTimeout(fallbackTimeout);
      };
    } else {
      // On iOS, show a manual prompt for adding to the home screen
      // setShowPrompt(true);
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowPrompt(false);
      });
    }
  };

  const handleDismissClick = () => {
    setShowPrompt(false);
    setPromptFallback(false);
  };

  return (
    showPrompt && (
      <div className="install-prompt">
   
        {!isIosDevice() && (
          <button onClick={handleInstallClick}>Add to Home Screen</button>
        )}
        <button onClick={handleDismissClick}>Dismiss</button>
      </div>
    )
  );
};

export default AddToHomeScreenPrompt;

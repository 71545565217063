// src/pages/Unauthorized.js
import React from 'react';
import { Link } from 'react-router-dom';

function Unauthorized() {
  return (
    <div className="container text-center mt-5">
      <h1>403 - Unauthorized</h1>
      <p>You do not have permission to view this page.</p>
      <Link to="/transactions" className="btn btn-primary mt-3">
        Go to Home
      </Link>
    </div>
  );
}

export default Unauthorized;

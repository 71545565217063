// src/pages/ReturnPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

function ReturnPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Inform the user of successful consent
    alert('Consent granted successfully! Transactions will be fetched shortly.');
    
    // Optionally, redirect to the Settings or Dashboard page after a delay
    const timer = setTimeout(() => {
      navigate('/settings');
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container text-center mt-5">
      <h2>Consent Successful</h2>
      <p>You will be redirected shortly...</p> 
    </div>
  );
}

export default ReturnPage;

// src/components/Footer.js
import React from 'react'; 

function Footer() {
  return (
    <footer className="footer bg-light text-center py-3">
      <div className="container">
        <p className="mb-0">Powered by GlobalCharge</p>
      </div>
    </footer>
  );
}

export default Footer;

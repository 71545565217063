// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function ProtectedRoute({ children, allowedRoles }) {
  const { auth } = useContext(AuthContext);
  const { user, role } = auth;

  // If not authenticated, redirect to login
  if (!auth.token) {
    return <Navigate to="/login" replace />;
  }

  // If roles are specified and user's role is not allowed, redirect to unauthorized page
  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  // If authenticated and authorized, render the child components
  return children;
}

export default ProtectedRoute;

// src/pages/Make.js

import React from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import './Make.css'; // Import the custom CSS for Make
import { Link } from 'react-router-dom'; // Import Link for navigation

function Make() {
  return (
    <div>
      {/* Navigation Bar */}
      <Navbar bg="light" expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand as={Link} to="/">TransferTracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
              <Nav.Link as={Link} to="/loginPage" active>Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Section: Sign Up & Login */}
      <Container className="section" style={{height: "100vh"}}>
        <h2>Sign Up & Login</h2>
        <div className="signup-login-container">
          {/* Sign Up Section */}
          <div className="section-content">
            <h3>Sign Up</h3>
            <p>
              Follow <Link to="/signup">this link</Link> to take you to our sign up page, this should be done by the admin member of staff.
            </p>
            <p>
              Adding each individual user is actioned from the portal, no need for them to create individual accounts!
            </p>
          </div>

          {/* Vertical HR between sign up and login */}
          <div className="vertical-hr"></div>

          {/* Login Section */}
          <div className="section-content">
            <h3>Login</h3>
            <p>
              If you’re already a user, please click <Link to="/login">here</Link> to be directed to the portal page.
            </p>
          </div>
        </div>
      </Container>

      {/* Footer */}
      <div className="footer">
        Powered by GlobalCharge <small>&copy; 2024</small>
      </div>
    </div>
  );
}

export default Make;

// src/components/Sidebar.js
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Sidebar.css'; // Import your custom CSS if any
import { Roles } from '../utils/roles';

function Sidebar() {
  const { auth } = useContext(AuthContext);
  const { user, role } = auth; 
  return (
    <div className="col-md-2 p-0">
      <div className="sidebar h-100 bg-light d-flex flex-column align-items-start p-3">
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            isActive ? 'nav-link active w-100 mb-2' : 'nav-link w-100 mb-2'
          }
        >
          Profile
        </NavLink>

        {/* Users Link: Visible to Admin and Manager */}
        {(role === 'admin' || role === 'manager') && (
          <NavLink
            to="/users"
            className={({ isActive }) =>
              isActive ? 'nav-link active w-100 mb-2' : 'nav-link w-100 mb-2'
            }
          >
            Users
          </NavLink>
        )}

        <NavLink
          to="/transactions"
          className={({ isActive }) =>
            isActive ? 'nav-link active w-100 mb-2' : 'nav-link w-100 mb-2'
          }
        >
          Transactions
        </NavLink>

        {/* Settings Link: Visible to Admin only */}
        {role === 'admin' && (
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive ? 'nav-link active w-100 mb-2' : 'nav-link w-100 mb-2'
            }
          >
            Settings
          </NavLink>
        )}

        <NavLink
          to="/logout"
          className={({ isActive }) =>
            isActive ? 'nav-link active w-100 mb-2' : 'nav-link w-100 mb-2'
          }
        >
          Logout
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;

// src/pages/Signup.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Use existing CSS for styling
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons for password visibility toggle
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Signup() {
  const { setAuth, isAuthenticated } = useContext(AuthContext);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [company, setCompany] = useState('');
  const [password, setPassword] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Toggle visibility of Password field
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

   

  // If already authenticated, redirect to home
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/transactions');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/register', { 
        name, email, mobile, company, password, recoveryEmail, role: "admin"
      });
      toast.success("Signup successful");
      setAuth({ company: response.data.company,token: response.data.token, user: response.data._id, role: response.data.role });
      setToken(response.data.token)
      setId(response.data._id);
      
      try {
        
        const response2 = await axios.post('/api/consent/consent-url', { 
          id: response.data._id, company: response.data.company
        });

        

        const { consentUrl } = response2.data;

        // Open the consent URL in a new tab/window
        window.open(consentUrl, '_blank');
        toast.info('Consent process initiated. Please complete it in the new tab.');
      } catch (err) {
        setError(err.response2?.data?.message || 'Failed to initiate consent');  
        toast.error(err.response2?.data?.message || 'Failed to initiate consent');
      }


      //navigate('/transactions'); // Redirect to transactions page after signup
    } catch (err) {
      toast.error(err.response?.data?.message || 'Signup failed');
      setError(err.response?.data?.message || 'Signup failed');
    }
  };

  return ( 
    <div className="login-container" style={{marginTop: "0px"}}>
      <h5>Sign Up</h5>
      <form className="login-form" onSubmit={handleSubmit} style={{maxWidth: "fit-content"}}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="tel"
          placeholder="Mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={toggleShowPassword}
            title={showPassword ? 'Hide Password' : 'Show Password'}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <input
          type="email"
          placeholder="Recovery Email"
          value={recoveryEmail}
          onChange={(e) => setRecoveryEmail(e.target.value)}
          required
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit" className="btn btn-primary" style={{ width: '100%', marginTop: '10px' }}>
          Sign Up
        </button>
      </form>
        
        {/* Toast Container for Notifications */}
        <ToastContainer />
    </div>
    
  );
}

export default Signup;

import React from 'react'; 
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import './Main.css'; // Updated CSS for centering content
import AddToHomeScreenPrompt from '../AddToHomeScreenPrompt';
import IosInstallPrompt from '../IosInstallPrompt';

function Main() {
  return (
    <div>
      {/* Navigation Bar */}
      
      <AddToHomeScreenPrompt />
      <IosInstallPrompt />
      <Navbar bg="light" expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="#">TransferTracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/about" active>About</Nav.Link>
              <Nav.Link href="/pricing">Pricing</Nav.Link>
              <Nav.Link href="/loginPage">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Section with Heading and Description */}
      <a href='/loginPage'><Container className="main-section text-center">
        <Row className="justify-content-center align-items-center">
          <Col md={12}>
            <h1 className="display-4">TransferTracker</h1>
            <p className="lead">Selective access to Bank Statement data for your non-financial staff</p> 
          </Col>
        </Row>
      </Container></a>

      {/* Footer */}
      <div className="footer">
        Powered by GlobalCharge <small>&copy; 2024</small>
      </div>
    </div>
  );
}

export default Main;

// src/pages/Settings.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './Settings.css'; // Your existing CSS
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form, InputGroup, FormControl  } from 'react-bootstrap'; // Importing React Bootstrap components
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons from react-icons

function Settings() {
  const { auth } = useContext(AuthContext);
  
  const [logo, setLogo] = useState(null); // State for selected logo file
  const [previewUrl, setPreviewUrl] = useState(''); 

   // Handle logo file selection and set preview URL
   const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    setPreviewUrl(URL.createObjectURL(file)); // Generate URL for image preview
  };

  // Upload logo and update for all users in the company
  const handleLogoUpload = async () => {
    if (!logo) {
      toast.error("Please select a logo to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("company", auth.company);

    try {
      const response = await axios.post('/api/settings/uploadLogo', formData, {
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success("Logo uploaded and updated successfully.");
      // Optionally, update the logo preview in the frontend
      setPreviewUrl(response.data.logoUrl);
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to upload logo");
    }
  };

  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [newKeyword, setNewKeyword] = useState('');
  const [newSubstring, setNewSubstring] = useState(''); 
  const [includeCredits, setIncludeCredits] = useState(true);
  const [includeDebits, setIncludeDebits] = useState(true);
  const [negativeSubstrings, setNegativeSubstrings] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentSubstring, setCurrentSubstring] = useState('');
  const [updatedSubstring, setUpdatedSubstring] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [substringToDelete, setSubstringToDelete] = useState('');
  let [currentTransactions, setCurrentTransactions] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [accountLinked, setAccountLinked] = useState(true);


  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [transactionsPerPage] = useState(10); // Transactions per page (10 by default)


  const [searchQuery, setSearchQuery] = useState('');

   // Pagination controls
   const indexOfLastTransaction = currentPage * transactionsPerPage;
   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
 
   const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // New state to control the Negative Substrings Modal
  const [showSubstringsModal, setShowSubstringsModal] = useState(false);
    // Handlers for Negative Substrings Modal
    const handleShowSubstringsModal = () => {
      setShowSubstringsModal(true);
    };
    
    const handleCloseSubstringsModal = () => {
      setShowSubstringsModal(false);
    };
  // Fetch Transactions
  const fetchTransactions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('/api/transactions/getTransactionsSettingPage', {
        headers: { Authorization: `Bearer ${auth.token}` },
        params: { page: 1, limit: 1000, includeExcluded: true, sort: 'date',company: auth.company }, // Fetch all transactions including excluded ones
      });
      setTransactions(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch transactions');
      toast.error(err.response?.data?.message || 'Failed to fetch transactions');
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch Negative Substrings
  const fetchNegativeSubstrings = async () => {
    try {
      const response = await axios.get('/api/settings/substrings', {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setNegativeSubstrings(response.data.negativeSubstrings);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch negative substrings');
      toast.error(err.response?.data?.message || 'Failed to fetch negative substrings');
    }
  };
 


  // Fetch data on component mount
  useEffect(() => { 
    handleRefreshBankFeed();
    fetchTransactions();
    fetchNegativeSubstrings();
  }, [auth.token]);
  
  // Toggle Include Credits
  const toggleIncludeCredits = async () => {
    try {
      const response = await axios.patch(
        '/api/transactions/includeCredits',
        { include: !includeCredits },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      setIncludeCredits(response.data.includeCredits);
      toast.success(response.data.message);

      // Re-fetch transactions to reflect changes
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update Include Credits');
      toast.error(err.response?.data?.message || 'Failed to update Include Credits');
    }
  };
  
  // Toggle Include Debits
  const toggleIncludeDebits = async () => {
    try {
      const response = await axios.patch(
        '/api/transactions/includeDebits',
        { include: !includeDebits },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      setIncludeDebits(response.data.includeDebits);
      toast.success(response.data.message);

      // Re-fetch transactions to reflect changes
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update Include Debits');
      toast.error(err.response?.data?.message || 'Failed to update Include Debits');
    }
  };
  


  // Toggle Exclusion Status
  const toggleExclusion = async (transactionId, currentStatus) => {
    try {
      const response = await axios.patch(
        `/api/transactions/single/${transactionId}`,
        { isExcluded: !currentStatus },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      // Update the local state
      setTransactions((prevTransactions) =>
        prevTransactions.map((tx) =>
          tx.id === transactionId ? response.data.transaction : tx
        )
      );

      // Show success notification
      toast.success(
        `Transaction ${!currentStatus ? 'excluded' : 'included'} successfully!`
      );
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update transaction');
      toast.error(err.response?.data?.message || 'Failed to update transaction');
    }
  };
  
  const handleRefreshBankFeed = async () => {
     
    try {
      const response = await axios.get(
        `/api/users/singleUser/${auth.user}`, 
        { headers: { Authorization: `Bearer ${auth.token}` } }
      ); 
      setDataUser(response.data.data.accountData)
      setTransactionId(response.data.data.transactionId)

      if(response.data.data.transactionId == undefined || response.data.data.transactionId == "undefined" || response.data.data.transactionId == null || response.data.data.transactionId == "null"){
        setAccountLinked(false);
        toast.error('Failed to update Bank Feed, please link your account first');
      }
      
      const response2 = await axios.post(
        '/api/notifications/transactions',
        {
          "transactionId": response.data.data.transactionId,
          "merchantUserId": auth.user,
          "accountData": response.data.data.accountData,  
          "company": auth.company,
        },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
 
      toast.success("Transactions fetched and updated successfully.");

      // Re-fetch transactions to reflect changes
      fetchTransactions();
     
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update Bank Feed');
      toast.error(err.response?.data?.message || 'Failed to update Bank Feed');
    } 
    
  }

   // Add Negative Keyword
  const handleAddKeyword = async () => {
    if (newKeyword.trim() === '') {
      toast.warning('Please enter a valid keyword.');
      return;
    }
    try {
      // Assume there's an API endpoint to add negative keywords
      const response = await axios.post(
        '/api/settings/keywords',
        { keyword: newKeyword },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setNewKeyword('');
      toast.success('Negative keyword added successfully!');
      // Re-fetch transactions to apply new keyword filters
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add negative keyword');
      toast.error(err.response?.data?.message || 'Failed to add negative keyword');
    }
  };
  
  // Add Negative Substring
  const handleAddSubstring = async () => {
    if (newSubstring.trim() === '') {
      toast.warning('Please enter a valid substring.');
      return;
    }
    try {
      // Assume there's an API endpoint to add negative substrings
      const response = await axios.post(
        '/api/settings/substrings',
        { substring: newSubstring },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      setNewSubstring('');
      toast.success("Negative 'substring' added successfully!");
      // Re-fetch transactions to apply new substring filters
      fetchTransactions();
      // Re-fetch negative substrings to update the list
      fetchNegativeSubstrings();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add negative substring');
      toast.error(err.response?.data?.message || 'Failed to add negative substring');
    }
  };
  
  // Determine if Transaction is Credit or Debit
  const isCredit = (tx) => tx.amount > 0;
  const isDebit = (tx) => tx.amount < 0;
  
  // Filter Transactions Based on Payment Filters
   currentTransactions = transactions.filter((tx) => { 
    if (isCredit(tx) && !includeCredits) return false;
    if (isDebit(tx) && !includeDebits) return false;
    return true;
  });
  currentTransactions = currentTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  


  const handleSearch = () => {
    const filteredData = transactions.filter(transaction =>
        transaction.description.toLowerCase().includes(searchQuery.toLowerCase())
    ); 
    console.log(transactions)
    console.log(filteredData)
    setCurrentTransactions(filteredData);
    setCurrentPage(1); // Reset to first page on search
  };


  
  // Handlers for Update Modal
  const handleShowUpdateModal = (substring) => {
    setCurrentSubstring(substring);
    setUpdatedSubstring(substring);
    setShowUpdateModal(true);
  };
  
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setCurrentSubstring('');
    setUpdatedSubstring('');
  };
  
  const handleUpdateSubstring = async () => {
    if (updatedSubstring.trim() === '') {
      toast.warning('Please enter a valid substring.');
      return;
    }
    try {
      const response = await axios.put(
        `/api/settings/substrings/${encodeURIComponent(currentSubstring)}`,
        { newSubstring: updatedSubstring },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      toast.success('Negative substring updated successfully!');
      handleCloseUpdateModal();
      // Re-fetch substrings and transactions
      fetchNegativeSubstrings();
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update substring');
      toast.error(err.response?.data?.message || 'Failed to update substring');
    }
  };
  
  // Handlers for Delete Modal
  const handleShowDeleteModal = (substring) => {
    setSubstringToDelete(substring);
    setShowDeleteModal(true);
  };
  
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSubstringToDelete('');
  };
  
  const handleDeleteSubstring = async () => {
    try {
      const response = await axios.delete(
        `/api/settings/substrings/${encodeURIComponent(substringToDelete)}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      toast.success('Negative substring deleted successfully!');
      handleCloseDeleteModal();
      // Re-fetch substrings and transactions
      fetchNegativeSubstrings();
      fetchTransactions();
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to delete substring');
      toast.error(err.response?.data?.message || 'Failed to delete substring');
    }
    
  };
  

  const handleConsent = async () => {
    try { 
      const response2 = await axios.post('/api/consent/consent-url', { 
        id: auth.user, company: auth.company
      }); 
      const { consentUrl } = response2.data; 
      // Open the consent URL in a new tab/window
      window.open(consentUrl, '_blank');
      toast.info('Consent process initiated. Please complete it in the new tab.');
    } catch (err) {
      setError(err.response2?.data?.message || 'Failed to initiate consent');  
      toast.error(err.response2?.data?.message || 'Failed to initiate consent');
    } 
  }


  
  return (   
    <div>
      {/* Filters */}
      <div className="row">
        <div className="col-md-4">
          <h5>Payment Filters</h5>
          <div className="form-check keyword-box" style={{ borderRadius: '5px', backgroundColor: 'white', padding: '10px', width: '70%' }}>
            <label className="form-check-label" htmlFor="includeCredits">Include Credits</label>
            <input
              className="form-check-input"
              type="checkbox"
              id="includeCredits"
              name="includeCredits"
              checked={includeCredits}
              onChange={toggleIncludeCredits}
              style={{ float: 'right' }}
            />
          </div>
          <div className="form-check keyword-box" style={{ borderRadius: '5px', backgroundColor: 'white', padding: '10px', width: '70%' }}>
            <label className="form-check-label" htmlFor="includeDebits">Include Debits</label>
            <input
              className="form-check-input"
              type="checkbox"
              id="includeDebits"
              name="includeDebits"
              checked={includeDebits}
              onChange={toggleIncludeDebits}
              style={{ float: 'right' }}
            />
          </div>

          {accountLinked && (
          <button
            className="btn btn-primary mt-3"
            onClick={handleRefreshBankFeed}
          >
            Refresh Bank Feed
          </button>
          )}

          {/* Consent Button */}


          {!accountLinked && (
            <button
              className="btn btn-primary mt-3"
              onClick={handleConsent}
            >
              Link Account
            </button>
          )}


          
        </div>
        <div className="col-md-8">
          <h5>All statement lines will appear unless excluded</h5> 
          {/* Adding Negative Substrings */}
          <div className='row' style={{ padding: '10px' }}>
            <label className="col-md-4" style={{ marginTop: '5px' }}>
              Enter Negative 'keyword' OR  'substring'
            </label>
            <input
              className="col-md-5 keyword-box"
              style={{ border: '1px solid #ced4da', padding: '5px', borderRadius: '5px', height: "100%" }}
              type="text" 
              value={newSubstring}
              onChange={(e) => setNewSubstring(e.target.value)}
              placeholder="Enter Negative 'keyword' OR 'substring'"
            />
            <button
              style={{ marginLeft: '10px', height: "100%", backgroundColor: "#b7b7b7", borderColor: "#b7b7b7" }}
              className="col-md-2 btn btn-secondary btn-sm"
              onClick={handleAddSubstring}
            >
              Add
            </button>
            
          </div>
          
                {/* Listing Negative Substrings */}
                <div className="d-flex justify-content-end" style={{ padding: '10px' }}>
                  <Button variant="info" onClick={handleShowSubstringsModal} style={{"color": "white"}}>
                    View Negative Substrings
                  </Button>
                </div>
                

                <div className="d-flex justify-content-end" style={{ padding: '10px' }}>
                  <h5>Upload Company Logo</h5>
                  <div>
                    {/* Logo file input */}
                    <input type="file" accept="image/*" onChange={handleLogoChange} />
                    {/* Preview selected logo */}
                    {previewUrl && (
                      <div className="logo-preview">
                        <img src={previewUrl} alt="Selected Logo" style={{ width: '150px', marginTop: '10px' }} />
                      </div>
                    )}
                    {/* Upload button */}
                    <button onClick={handleLogoUpload} className="btn btn-primary mt-2">
                      Upload Logo
                    </button>
                  </div>
                </div>
 

      
        </div>
      </div>



      {/* Transactions Table */}
      <table className="table table-hover mt-4">
        <thead>
          <tr style={{ backgroundColor: "#fff" }}>
            <th>Date</th>
            <th>Payee</th>
            <th>Reference</th>
            <th>Value</th>
            <th>Include</th>
            <th>Exclude</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" className="text-center">
                Loading transactions...
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="6" className="text-center text-danger">
                {error}
              </td>
            </tr>
          ) : currentTransactions.length > 0 ? (
            currentTransactions.map((tx) => (
              <tr key={tx.id}
                className={
                   tx.excludedByNegativeString 
                    ? 'excluded-negative' 
                    : tx.isExcluded 
                    ? 'excluded' 
                    : ''
                }
               >
                <td>{new Date(tx.date).toLocaleDateString('en-GB')}</td>
                <td>{tx.description}</td>
                <td>{tx.reference || '—'}</td>
                <td>£{tx.amount.toFixed(2)}</td>
                <td>
                  {/* Include Toggle */}
                  {!tx.isExcluded ? (
                    <button
                      className="exclude btn btn-secondary btn-sm"
                      onClick={() => toggleExclusion(tx.id, tx.isExcluded)}
                      title="Exclude Transaction"
                    >
                      x
                    </button>
                  ) : (
                    <input
                      type="checkbox"
                      className="  form-check-input"
                      checked={!tx.isExcluded}
                      onChange={() => toggleExclusion(tx.id, tx.isExcluded)}
                      title="Include Transaction"
                    />
                  )}
                </td>
                <td>
                  {/* Exclude Toggle */}
                  {tx.isExcluded ? (
                    <button
                      className="include btn btn-secondary btn-sm"
                      onClick={() => toggleExclusion(tx.id, tx.isExcluded)}
                      title="Include Transaction"
                    >
                      x
                    </button>
                  ) : (
                    <input
                      type="checkbox"
                      className="  form-check-input"
                      checked={tx.isExcluded}
                      onChange={() => toggleExclusion(tx.id, tx.isExcluded)}
                      title="Exclude Transaction"
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No Transactions Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        {[...Array(Math.ceil(transactions.length / transactionsPerPage)).keys()].map((page) => (
          <button
            key={page + 1}
            onClick={() => paginate(page + 1)}
            className={`page-item ${page + 1 === currentPage ? 'active' : ''}`}
          >
            {page + 1}
          </button>
        ))}
      </div>

      <p className="table-caption">Denotes exclude by keyword, change by toggling checking box</p>

      {/* Toast Container for Notifications */}
      <ToastContainer />

      {/* Update Substring Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Negative Substring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUpdatedSubstring">
              <Form.Label>New Substring</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter new substring"
                value={updatedSubstring}
                onChange={(e) => setUpdatedSubstring(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateSubstring}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Substring Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Negative Substring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the substring "{substringToDelete}"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubstring}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> 


      {/* Negative Substrings List Modal */}
      <Modal 
        show={showSubstringsModal} 
        onHide={handleCloseSubstringsModal}
        size="lg" // Optional: Makes the modal larger
        aria-labelledby="negative-substrings-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Negative Substrings</Modal.Title>
        </Modal.Header>
        <Modal.Body style={negativeSubstrings.length >= 8 ? { maxHeight: '500px', overflowY: 'auto' } : {}}>
          {negativeSubstrings.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Substring</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {negativeSubstrings.map((substring, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{substring}</td>
                    <td>
                      <Button 
                        variant="outline-primary" 
                        size="sm" 
                        onClick={() => handleShowUpdateModal(substring)}
                        className="me-2"
                        title="Edit"
                      >
                        <FaEdit />
                      </Button>
                      <Button 
                        variant="outline-danger" 
                        size="sm" 
                        onClick={() => handleShowDeleteModal(substring)}
                        title="Delete"
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> 
          ) : (
            <p className="text-muted">No Negative Substrings available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSubstringsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
    
  );
}

export default Settings;

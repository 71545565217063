import React, { useState, useEffect } from 'react';
import './AddToHomeScreenPrompt.css';

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent) && !window.navigator.standalone;
};

const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

const IosInstallPrompt = () => {
  const [showIosPrompt, setShowIosPrompt] = useState(false);

  useEffect(() => {
    // Show the prompt if on iOS Safari and not in standalone mode
    if (isIos() && !isInStandaloneMode()) {
      setShowIosPrompt(true);
    }
  }, []);

  const handleDismissClick = () => {
    setShowIosPrompt(false);
  };

  return (
    showIosPrompt && (
      <div className="ios-install-banner">
        <p>
          To install this app on your iPhone or iPad:
          <br />
          1. Tap the <span className="instructions">
            <img src="/path-to-your-share-icon.png" alt="Share Icon" className="share-icon" />
          </span> button at the bottom of the screen.
          <br />
          2. Scroll down and select "Add to Home Screen."
        </p>
        <button onClick={handleDismissClick} className="dismiss-btn">Dismiss</button>
      </div>
    )
  );
};

export default IosInstallPrompt;

// src/pages/Homepage.js

import React from 'react';
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import './Homepage.css'; // Create this CSS file for custom styles

function Homepage() {
  return (
    <div>
      {/* Navigation Bar */}
      <Navbar bg="light" expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="#">TransferTracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/about" active>About</Nav.Link>
              <Nav.Link href="/pricing">Pricing</Nav.Link>
              <Nav.Link href="/loginPage">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Section 1: What is TransferTracker? */}
      <Container className="section">
        <Row className="image-and-text">
          <Col md={6} className="img-placeholder">
            Image of user end
          </Col>
          <Col md={6} className="content">
            <h2>What is TransferTracker?</h2>
            <p>
              Occasionally it’s important for non-financial staff to know if and when a payment arrived in the company bank account. Tasks like releasing a motor vehicle, preparing a delivery, signing a contract, etc., can be held up without such access.
            </p>
            <p>
              Our system provides limited access to statement entries, allowing filters to be set, which can be tailored to the specific needs for your business. Ensuring that only relevant information is shared with non-financial staff.
            </p>
          </Col>
        </Row>
      </Container>

      {/* Section 2: How does it work? */}
      <Container className="section">
        <Row className="image-and-text flex-md-row-reverse">
          <Col md={6} className="img-placeholder">
            Image of Admin end
          </Col>
          <Col md={6} className="content">
            <h2>How does it work?</h2>
            <p>
              TransferTracker provides you with two different levels of access: for staff or the chosen admin. With these controls, you can select and exclude any transaction you like or set up filters to automatically exclude ones containing certain terms.
            </p>
            <p>
              With this, we aim to enable the perfect level of transparency for the staff that need it.
            </p>
          </Col>
        </Row>
      </Container>
 
    </div>
  );
}

export default Homepage;

// src/pages/Logout.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Button } from 'react-bootstrap';
import './Logout.css'; // Optional: Create this file for additional styling

function Logout() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  // Handler for confirming logout
  const handleLogout = () => {
    // Remove token and user from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // Reset auth context
    setAuth({ token: null, user: null });

    // Redirect to login page
    navigate('/login');
  };

  // Handler for canceling logout
  const handleCancel = () => {
    // Redirect to home page or previous page
    navigate('/'); // Adjust the path as needed
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div className="logout-card p-5 shadow rounded">
        <h3 className="mb-4">Are you sure you'd like to logout?</h3>
        <div className="d-flex justify-content-center gap-3">
          <Button 
            variant="danger" 
            onClick={handleLogout}
            aria-label="Confirm Logout"
            title="Confirm Logout"
          >
            Yes, Logout
          </Button>
          <Button 
            variant="secondary" 
            onClick={handleCancel}
            aria-label="Cancel Logout"
            title="Cancel Logout"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
